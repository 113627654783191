import { styled } from '@cp/ds/src/theme/styled';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { OwnerState } from './ownerState';
import { CssTokens, name } from './const';

export const AdvantageRoot = styled(Box, { name, slot: 'root' })<{ ownerState: OwnerState }>(({ theme, ownerState }) => ({
  borderRadius: theme.borderRadius.small,
  padding: `5px ${theme.spacing(1)}`,
  display: 'inline-flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  alignItems: 'center',
  userSelect: 'none',
  boxSizing: 'border-box',
  lineHeight: theme.typography.h2.lineHeight,
  minHeight: CssTokens.height(`calc(${theme.typography.h2.fontSize} * ${theme.typography.h2.lineHeight})`),
  height: CssTokens.height('40px'),
  color: CssTokens.colorText(),
  backgroundColor: CssTokens.colorBackground(),
  ...(ownerState.bordered && {
    border: `1px solid ${CssTokens.colorBorder(theme.palette['gray light'])}`,
  }),
}));

export const AdvantageIcon = styled(Typography, { name, slot: 'icon' })({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: CssTokens.iconSize('24px'),
  height: CssTokens.iconSize('24px'),
  fontSize: CssTokens.iconSize('24px'),
});
