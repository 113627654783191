import { Box } from '../../box';
import { CheckerProps } from '../model';
import { checkerRootSizeMap } from '../const';
import { styled } from '../../../theme';

export const CheckerRoot = styled(Box)<CheckerProps>(({ theme, size = 'normal', variant }) => {
  return {
    width: checkerRootSizeMap[size],
    height: checkerRootSizeMap[size],
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette['gray middle']}`,
    color: theme.palette['brand pink'],
    borderRadius: variant === 'checkbox' ? '4.5px' : '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover, &:focus': {
      borderColor: theme.palette.gray,
    },
  };
});
