import { QueryParamsDictionaryValue, ValueDecoder } from './types';
export const composeDecoders =
  (...d: ValueDecoder[]): ValueDecoder =>
  (v) => {
    let decoded: QueryParamsDictionaryValue = v;
    for (const decoder of d) {
      decoded = decoder(v);
      if (typeof decoded !== 'string') {
        return decoded;
      }
    }
    return decoded;
  };
