import React, { FC, useCallback, useMemo } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { SxProps } from '@cp/ds';
import { mergeSx } from '@cp/ds/src/utils/sx/merge';
import { getDialogMemberName, getMessageDateTitle } from '../../lib';
import { useIsOnline } from '../../model';
import { MemberPhoto } from '../memberPhoto';
import { useDialogContext } from '../context';
import { DialogNotificationCounter } from '../notificationCounter';

export interface DialogPreviewProps {
  dialogId: number;
  participant: DialogMemberViewModel;
  onSelect: (dialogId: number) => void;
  unreadMessagesCount?: number;
  component?: React.ElementType;
  message?: DialogMessageViewModel;
  sx?: SxProps;
  messageLines?: number;
}

export const DialogPreview: FC<DialogPreviewProps> = ({
  dialogId,
  participant,
  message,
  unreadMessagesCount,
  onSelect,
  component,
  sx,
  messageLines = 1,
}) => {
  const theme = useTheme();
  const onClick = useCallback(() => {
    onSelect(dialogId);
  }, [dialogId, onSelect]);
  const lastMessageDate = useMemo(() => (message ? getMessageDateTitle(message.createdAt) : null), [message]);
  const { MessageComponent } = useDialogContext();
  const online = useIsOnline(participant);

  return (
    <Box
      component={component}
      onClick={onClick}
      role="button"
      sx={mergeSx(
        {
          py: 2,
          borderBottom: `1px solid ${theme.palette['gray middle']}`,
          display: 'flex',
          cursor: 'pointer',

          '&:hover [data-affected-by-hover]': {
            color: theme.palette['brand pink'],
          },
        },
        sx,
      )}
      tabIndex={0}
    >
      <MemberPhoto member={participant} online={online} sx={{ mr: 2 }} />
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            data-affected-by-hover="true"
            sx={{
              transition: theme.transitions.create(['color'], {
                duration: theme.transitions.duration.short,
              }),
            }}
            variant="button"
          >
            {getDialogMemberName(participant)}
          </Typography>
          <Typography sx={{ color: theme.palette.gray }} variant="caption">
            {lastMessageDate}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              flexShrink: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: messageLines,
              lineClamp: messageLines,
              wordBreak: 'break-word',
            }}
            variant="body"
          >
            {message && <MessageComponent dialogId={dialogId} isPreview message={message} />}
          </Typography>
          <DialogNotificationCounter value={unreadMessagesCount} />
        </Box>
      </Box>
    </Box>
  );
};
