import React from 'react';
import { ISuggestionsContext } from './appSuggestionsContext';

export type IFormAppContext<CitySuggestion = unknown, LocationSuggestion = unknown> = Pick<
  ISuggestionsContext<CitySuggestion, LocationSuggestion>,
  'onSearchCity' | 'onSearchLocation'
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormAppContext = React.createContext<IFormAppContext<any, any>>({});

export const useFormAppContext = function <CitySuggestion, LocationSuggestion>(): IFormAppContext<CitySuggestion, LocationSuggestion> {
  return React.useContext(FormAppContext);
};
