import { BoxProps } from '@cp/ds/src/components/box/model';
import { Typography } from '@cp/ds/src/components/typography';
import React from 'react';
import { IAdvantage } from '../../model';
import { AdvantageIcon, AdvantageRoot } from './styled';
import { name } from './const';

export interface AdvantageProps extends IAdvantage, BoxProps {
  icon?: string | React.ReactNode;
  bordered?: boolean;
  checked?: boolean;
}

export const Advantage: React.FC<AdvantageProps> = ({ icon, title, name: _, bordered, ...props }) => {
  const ownerState = { bordered };
  return (
    <AdvantageRoot ownerState={ownerState} {...props}>
      {icon ? <AdvantageIcon>{icon}</AdvantageIcon> : null}
      <Typography variant="caption">{title}</Typography>
    </AdvantageRoot>
  );
};
Advantage.displayName = name;
