import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormAppContext, IFormAppContext } from '../formAppContext';
import { useAppSuggestionsContext } from '../appSuggestionsContext';
import { AppFormProviderProps } from './model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppFormProvider = React.forwardRef<IFormAppContext<any, any>, AppFormProviderProps>(({ form, children }, ref) => {
  const suggestionsContextValue = useAppSuggestionsContext();

  const value = React.useMemo<IFormAppContext>(() => {
    const { onSearchCity, onSearchLocation } = suggestionsContextValue;

    return {
      onSearchCity,
      onSearchLocation,
    };
  }, []);

  React.useImperativeHandle(ref, () => value, [value]);

  return (
    <FormAppContext.Provider value={value}>
      <FormProvider {...form}>{children}</FormProvider>
    </FormAppContext.Provider>
  );
});
