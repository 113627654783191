import React from 'react';
import { OnlineStatus, OnlineStatusTag } from '@cp/ds/src/components/onlineStatus';
import { FormattingStrategy, approximateStrategy, getLastSeenText, isOnline } from '../model';

export interface ClientOnlineStatusTagProps {
  clientProfile: { lastActivityAt: string; isOnline: boolean };
  strategy?: FormattingStrategy;
  now?: Date;
}

export const ClientOnlineStatusTag: React.FC<ClientOnlineStatusTagProps> = ({
  clientProfile,
  strategy = approximateStrategy,
  now = new Date(),
}) => {
  const onlineStatus = isOnline(clientProfile);
  const statusText = getLastSeenText(clientProfile, strategy, now);

  return (
    <OnlineStatusTag colors={{ [OnlineStatus.Offline]: 'brand pink' }} online={onlineStatus}>
      {statusText}
    </OnlineStatusTag>
  );
};
