import React from 'react';
import { styled } from '../../../theme/styled';
import { Box } from '../../box';
import { CheckerProps, CheckerSize } from '../model';
import { radioCheckerSizeMap } from '../const';
import { CheckerRoot } from './root';

const RadioCheckmark = styled(Box)<{ size: CheckerSize }>(({ theme, size = 'normal' }) => ({
  width: radioCheckerSizeMap[size],
  height: radioCheckerSizeMap[size],
  borderRadius: '50%',
  backgroundColor: theme.palette['brand pink'],
}));

export const RadioChecker = ({ checked, size = 'normal', sx }: CheckerProps) => {
  return (
    <CheckerRoot size={size} sx={sx} variant="radio">
      <RadioCheckmark size={size} sx={{ visibility: checked ? 'visible' : 'hidden' }} />
    </CheckerRoot>
  );
};
