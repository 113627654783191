import { CheckerSize } from './model';

export const checkerRootSizeMap: Record<CheckerSize, number> = {
  'normal': 24,
  'small': 18,
};

export const radioCheckerSizeMap: Record<CheckerSize, number> = {
  'normal': 12,
  'small': 8,
};
