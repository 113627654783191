import { ILogger } from '@common/model/logger';
import { compile } from 'path-to-regexp';

type ParamData = Object;

const cache: Record<string, ReturnType<typeof compile>> = {};
const cacheLimit = 10000;
let cacheCount = 0;

const compilePath = (path: string, options: { logger?: ILogger } = {}): ReturnType<typeof compile> => {
  if (cache[path]) {
    return cache[path];
  }

  try {
    const generator = compile(path);

    if (cacheCount < cacheLimit) {
      cache[path] = generator;
      cacheCount++;
    }

    return generator;
  } catch (err) {
    options.logger?.error(`[common/routing/lib]: Failed to compile path "${path}". Path generating failed.`);
    throw err;
  }
};

/**
 * Public API for generating a URL pathname from a path and parameters.
 */
export const generatePath = (path: string = '/', params: ParamData = {}, options: { logger?: ILogger } = {}): string => {
  return path === '/' ? path : compilePath(path, options)(params);
};
