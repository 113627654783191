import { differenceInHours, differenceInMinutes, formatDuration, formatRelative } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { FormattingStrategy } from './formattingStrategy';

export const distanceStrategy: FormattingStrategy = (lastActivityDate, now) => {
  const diffInMinutes = differenceInMinutes(now, lastActivityDate);
  const diffInHours = differenceInHours(now, lastActivityDate);

  if (diffInMinutes <= 15) {
    return 'в сети';
  }

  if (diffInHours < 1) {
    return `${formatDuration({ minutes: diffInMinutes }, { locale: ru })} назад`;
  }
  if (diffInHours <= 23) {
    return `${formatDuration({ hours: diffInHours }, { locale: ru })} назад`;
  }

  return formatRelative(lastActivityDate, now, {
    locale: {
      ...ru,
      formatRelative: (token: string) => {
        switch (token) {
          case 'today':
            return `${formatDuration({ hours: diffInHours }, { locale: ru })} назад`;
          case 'yesterday':
            return "'вчера в' p";
          default:
            if (diffInHours <= 48) {
              return "'позавчера в' p";
            }
            return 'dd.MM.yy';
        }
      },
    },
  });
};
