import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';
import { useMemo } from 'react';
import { DialogMemberType } from '@common/model/dialog/dialogMemberType';
import { isOnline } from '../../clientOnlineStatus/@x/dialog';

export const isMemberOnline = (member?: DialogMemberViewModel) => {
  if (!member) {
    return false;
  }
  if (member.type === DialogMemberType.Bot) {
    return true;
  }
  return isOnline(member);
};

export const useIsOnline = (member?: DialogMemberViewModel) => {
  return useMemo(() => isMemberOnline(member), [member]);
};
