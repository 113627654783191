import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { createThemeYH } from '@cp/ds';
import { useSelector } from 'react-redux';
import { TokenProvider } from 'src/shared/ui/theme';
import { themeNameSelector } from '../redux/slices/theme';
import { routesConfig } from './routes';
import { ModalProvider } from './shared/components/modalProvider';
import { ResumeViewerProvider } from './providers/resumeViewer';
import { RouterProvider } from './providers/router';
import { ClientWebRouteResolver } from './providers/routeResolver';
import { ConfigContextProvider } from './config';

export const App: React.FC = () => {
  const themeName = useSelector(themeNameSelector);

  const theme = React.useMemo(() => createThemeYH(themeName), [themeName]);

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <ConfigContextProvider>
        <RouterProvider>
          <TokenProvider>
            <ClientWebRouteResolver>
              <ResumeViewerProvider>
                {renderRoutes(routesConfig)}
                <ModalProvider />
              </ResumeViewerProvider>
            </ClientWebRouteResolver>
          </TokenProvider>
        </RouterProvider>
      </ConfigContextProvider>
    </ThemeProvider>
  );
};
