import { parseBoolean } from './parser/boolean';
import { parseString } from './parser/string';

const createConfig = () => {
  const baseConfig = {
    CORE_SERVER_ORIGIN: parseString(process.env.CORE_SERVER_ORIGIN),
    CORE_SERVER_WEBSOCKET_ORIGIN: parseString(process.env.CORE_SERVER_WEBSOCKET_ORIGIN),
    CLIENT_WEB_ORIGIN: parseString(process.env.CLIENT_WEB_ORIGIN),
    IS_PAID_FEATURES_ARE_FREE: parseBoolean(process.env.IS_PAID_FEATURES_ARE_FREE),
    CLIENT_SERVER_CACHE_ROUTES: parseBoolean(process.env.CLIENT_SERVER_CACHE_ROUTES),
    CDN_SERVER: parseString(process.env.CDN_SERVER),
  };
  return Object.freeze(baseConfig);
};

export const config = createConfig();
