import React from 'react';
import { Checker } from '../checker';
import { Typography } from '../typography';
import { styled } from '../../theme/styled';
import { CheckboxProps } from './model';
import { StyledInput } from './components/styledInput';
import { useUtilityClasses } from './useUtilityClasses';

const StyledLabel = styled('label')(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  gap: theme.spacing(1),
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}));

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const classes = useUtilityClasses(props);
  return (
    <StyledLabel className={classes.root} sx={props.sx}>
      <StyledInput
        checked={props.value}
        disabled={props.disabled}
        onChange={(e) => props.onChange?.(e.target.checked)}
        ref={ref}
        type="checkbox"
      />
      <Checker checked={props.value} size={props.size} variant="checkbox" />
      <Typography variant={props.size === 'small' ? 'caption' : 'body'}>{props.label}</Typography>
    </StyledLabel>
  );
});
