import { getClientLastSeen } from './getClientLastSeen';
import { getLastSeenLabel } from './getLastSeenLabel';
import { HasOnlineStatus } from './hasOnlineStatus';
import { FormattingStrategy } from './formattingStrategy';

export const getLastSeenText = (entity: HasOnlineStatus, strategy: FormattingStrategy, now = new Date()) => {
  if (entity.isOnline) {
    return 'в сети';
  }
  const lastSeen = getClientLastSeen(strategy, entity.lastActivityAt, now);

  return lastSeen ? `${getLastSeenLabel(entity.sex)} ${lastSeen}` : 'не в сети';
};
