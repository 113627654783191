import React from 'react';
import { ClientType } from '@common/model/client/clientType';
import { ClientTypeIcon } from 'src/entities/client';
import { coreClientStats } from 'src/widgets/commonStats/api';
import { formatNumber } from '../lib';
import { StatItem } from './statItem';

export const Specialists = () => {
  const { data: total, isLoading } = coreClientStats.endpoints.getClientStats.useQuery({ type: ClientType.Specialist });
  const { data: published } = coreClientStats.endpoints.getClientStats.useQuery({
    type: ClientType.Specialist,
    hasPublishedCatalogItem: true,
  });
  const { data: push } = coreClientStats.endpoints.getClientStats.useQuery({
    type: ClientType.Specialist,
    hasPublishedCatalogItem: true,
    notificationPlatforms: { isNotEmpty: true },
  });
  return (
    <StatItem
      isLoading={isLoading}
      label={<ClientTypeIcon type={ClientType.Specialist} />}
      title="Всего специалистов / всего опубликованных / опубликованных с Push"
      value={
        <>
          {formatNumber(total)} / {formatNumber(published)} / {formatNumber(push)}
        </>
      }
    />
  );
};
