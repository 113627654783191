import React, { ReactNode } from 'react';
import { Box, BoxProps } from '../../../box';
import { useTheme } from '../../../../hooks/useTheme';

export interface OnlineStatusTextProps extends BoxProps {
  online: boolean;
  colored?: boolean;
  children?: ReactNode;
  datetime?: string;
}

export const OnlineStatusText: React.FC<OnlineStatusTextProps> = ({ children, online, colored = false, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      component="span"
      sx={{
        color: colored && online ? theme.palette.green : theme.palette.gray,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
