// @ts-expect-error esm import
import { cssToken } from '@cp/ui/utils';

export const name = 'Advantage';
export const CssTokens = {
  iconSize: cssToken(name, ['icon', 'size']),
  height: cssToken(name, 'height'),
  colorBorder: cssToken(name, ['color', 'border']),
  colorBackground: cssToken(name, ['color', 'background']),
  colorText: cssToken(name, ['color', 'text']),
};
