import { CareerId } from '@common/const/catalog/career';
import { useMemo } from 'react';
import { coreCareerApi } from '../api';
import { useCareerDeclination } from './useCareerDeclination';

type Option = { value: CareerId; label: string };

export const useCareerOptions = (): Option[] => {
  const declination = useCareerDeclination();
  const { data } = coreCareerApi.endpoints.getCareers.useQuery();
  return useMemo(() => {
    const options: Option[] = [];
    for (const key in data) {
      options.push({ value: Number(key), label: declination(1, Number(key)) });
    }
    return options;
  }, [data, declination]);
};
