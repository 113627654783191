import { useClientWebResolver } from '@cp/entities/clientWebRoutes';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { useMemo } from 'react';

export const useVacancyUrl = (m: AdminCatalogItemViewModel) => {
  const resolver = useClientWebResolver();
  return useMemo(
    () =>
      resolver.resolve({
        page: ClientWebPage.CatalogItem,
        pageProps: { catalogType: m.type, careerId: m.careerId },
        pathParams: { id: m.id },
        cityAlias: m.city?.alias,
      }),
    [resolver, m.type, m.careerId, m.id, m.city],
  );
};
