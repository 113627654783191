import React, { FC } from 'react';
import { Skeleton } from '@cp/ds/src/components/skeleton';
import { Typography } from '@cp/ds/src/components/typography';
import { ClientOnlineStatusTag, distanceStrategy } from '@cp/entities/clientOnlineStatus';
import { getInitialParticipant, useCurrentUserContext } from '@cp/entities/dialog';
import { DialogMemberType } from '@common/model/dialog/dialogMemberType';
import { Stack } from '@cp/ds/src/components/stack';
import { LocationIcon } from '@cp/ds/src/icons/location';
import { coreLocationApi } from '@cp/entities/location';
import { ClientType } from '@common/model/client/clientType';
import { Link } from '@cp/ds/src/components/link';
import { DialogViewModel } from '@common/model/dialog/dialogViewModel';
import { RubSolidIcon } from '@cp/ds/src/icons';
import { coreClientProfileApi } from 'src/entities/clientProfile/api';
import { DialogParticipantInfo } from 'src/entities/dialogParticipanInfo';
import { ClientProfileTags, useClientProfileTags } from 'src/entities/clientProfileTags';
import { useViewResume } from 'src/features/resume/view';

export interface DialogHeaderProps {
  dialog?: DialogViewModel;
}

export const DialogHeader: FC<DialogHeaderProps> = ({ dialog }) => {
  const userProvider = useCurrentUserContext();
  const participant = getInitialParticipant(dialog?.members ?? [], userProvider.getUser());
  const { data: clientProfile } = coreClientProfileApi.useGetClientProfileQuery(
    { clientId: participant?.entityId as number },
    { skip: !participant || participant.type !== DialogMemberType.Client },
  );
  const { data: clientCity } = coreLocationApi.useGetCityByIdQuery({ id: clientProfile?.cityId as number }, { skip: !clientProfile });
  const clientProfileTags = useClientProfileTags(clientProfile, { tagTypes: ['career', 'sex', 'age'] });
  const [viewResume] = useViewResume();

  return (
    <Stack direction="column" sx={{ alignItems: 'stretch' }}>
      <Stack direction="row" spacing={1.5} sx={{ alignItems: 'center', marginBottom: 1 }}>
        {clientCity ? (
          <Stack direction="row" spacing={0.5}>
            <LocationIcon />
            <Typography variant="caption bold">{clientCity.translation.name}</Typography>
          </Stack>
        ) : (
          <Skeleton width={140} />
        )}

        {participant ? <ClientOnlineStatusTag clientProfile={participant} strategy={distanceStrategy} /> : undefined}
        {clientProfile?.subscriptionId && <RubSolidIcon />}
      </Stack>

      {participant ? (
        <DialogParticipantInfo
          avatarSize={60}
          lowerBlock={
            participant?.type === DialogMemberType.Client && clientProfile ? (
              <Stack direction="row" sx={{ marginTop: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                <ClientProfileTags tags={clientProfileTags} />
                <Link
                  color="pink"
                  onClick={() => {
                    if (clientProfile.type !== ClientType.Specialist) {
                      alert('Просмотр профиля нанимателей пока не реализован');
                      return;
                    }

                    if (!clientProfile.mainResumeId) {
                      alert('Main resume id is not set for client');
                      return;
                    }

                    viewResume(clientProfile.mainResumeId);
                  }}
                  size="small"
                >
                  Открыть профиль
                </Link>
              </Stack>
            ) : undefined
          }
          participant={participant}
          rightBlockSx={{ flexGrow: 1 }}
        />
      ) : (
        <Skeleton height={48} width={140} />
      )}
    </Stack>
  );
};
