import { FormField } from '@cp/ds/src/components/form';
import React from 'react';
import { CityViewModel } from '@common/model/location/cityViewModel';
import { useFormAppContext } from '@cp/utils/form/formAppContext';
import { CitySearchFormFieldProps } from './model';
import { NAME_DEFAULT } from './const';

export { NAME_DEFAULT as CITY_SEARCH_FORM_FIELD_NAME_DEFAULT } from './const';
export * from './model';

const getKey = (value: CityViewModel) => {
  return `${value.id}`;
};

const getLabel = (suggestion: CityViewModel) => suggestion.translation.name;

export const CitySearchFormField: React.FC<CitySearchFormFieldProps> = ({
  name = NAME_DEFAULT,
  resetQueryOnFocus = true,
  rollbackQueryOnNotSelected = true,
  ...fieldProps
}) => {
  const formAppContext = useFormAppContext<CityViewModel, unknown>();

  if (!formAppContext.onSearchCity) {
    // eslint-disable-next-line quotes
    throw new Error("Parameter 'onSearchCity' in FormAppContext should be provided when CitySearchFormField is used");
  }

  return (
    <FormField
      getKey={getKey}
      getLabel={getLabel}
      name={name}
      onRequestSuggestions={formAppContext.onSearchCity}
      queryMinLenthToRequestSuggestions={0}
      resetQueryOnFocus={resetQueryOnFocus}
      rollbackQueryOnNotSelected={rollbackQueryOnNotSelected}
      type="autocomplete"
      {...fieldProps}
    />
  );
};
