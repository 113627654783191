/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable default-case */
/* eslint-disable import/no-duplicates */
import { Day, isSameWeek } from 'date-fns';
import { ru as defaultRU } from 'date-fns/locale';

export type DeclensionScheme = {
  one?: string;
  singularNominative: string;
  singularGenitive: string;
  pluralGenitive: string;
};

export const declension = (scheme: DeclensionScheme, count: number) => {
  // scheme for count=1 exists
  if (scheme.one !== undefined && count === 1) {
    return scheme.one;
  }

  const rem10 = count % 10;
  const rem100 = count % 100; // 1, 21, 31, ...

  if (rem10 === 1 && rem100 !== 11) {
    return scheme.singularNominative.replace('{{count}}', String(count)); // 2, 3, 4, 22, 23, 24, 32 ...
  } else if (rem10 >= 2 && rem10 <= 4 && (rem100 < 10 || rem100 > 20)) {
    return scheme.singularGenitive.replace('{{count}}', String(count)); // 5, 6, 7, 8, 9, 10, 11, ...
  } else {
    return scheme.pluralGenitive.replace('{{count}}', String(count));
  }
};

const accusativeWeekdays = ['воскресенье', 'понедельник', 'вторник', 'среду', 'четверг', 'пятницу', 'субботу'];

const lastWeek = (day: Day): string => {
  const weekday = accusativeWeekdays[day];

  switch (day) {
    case 0:
      return `'в прошлое ${weekday}'`;
    case 1:
    case 2:
    case 4:
      return `'в прошлый ${weekday}'`;
    case 3:
    case 5:
    case 6:
      return `'в прошлую ${weekday}'`;
  }
};

const thisWeek = (day: Day) => {
  const weekday = accusativeWeekdays[day];

  if (day === 2 /* Tue */) {
    return `'во ${weekday}'`;
  } else {
    return `'в ${weekday}'`;
  }
};

const nextWeek = (day: Day) => {
  const weekday = accusativeWeekdays[day];

  switch (day) {
    case 0:
      return `'в следующее ${weekday}'`;
    case 1:
    case 2:
    case 4:
      return `'в следующий ${weekday}'`;
    case 3:
    case 5:
    case 6:
      return `'в следующую ${weekday}'`;
  }
};

const formatRelativeLocale = {
  lastWeek: <DateType extends Date>(date: DateType, baseDate: DateType, options?: any): string => {
    const day = date.getDay() as Day;
    if (isSameWeek(date, baseDate, options)) {
      return thisWeek(day);
    } else {
      return lastWeek(day);
    }
  },
  yesterday: 'вчера',
  today: 'сегодня',
  tomorrow: 'завтра',
  nextWeek: <DateType extends Date>(date: DateType, baseDate: DateType, options?: any): string => {
    const day = date.getDay() as Day;
    if (isSameWeek(date, baseDate, options)) {
      return thisWeek(day);
    } else {
      return nextWeek(day);
    }
  },
  other: 'P',
};

const formatRelative = (token, date, baseDate, options) => {
  const format = formatRelativeLocale[token];

  if (typeof format === 'function') {
    return format(date, baseDate, options);
  }

  return format;
};

export const ru = { ...defaultRU, formatRelative };
