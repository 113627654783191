import { differenceInMinutes } from 'date-fns';
import { HasOnlineStatus } from './hasOnlineStatus';

/**
 *  Get client online status
 */
export const isOnline = (client: HasOnlineStatus, now = new Date()): boolean => {
  if (client.isOnline) {
    return true;
  }
  if (client.lastActivityAt) {
    return differenceInMinutes(now, new Date(client.lastActivityAt)) <= 15;
  }
  return false;
};
