import React, { ReactNode } from 'react';
import { Tag } from '../../../tag';
import { OnlineStatusText } from '../onlineStatusText';
import { OnlineStatusMarker, OnlineStatusMarkerProps } from '../onlineStatusMarker';

export interface OnlineStatusTagProps {
  online: boolean;
  children?: ReactNode;
  colors?: OnlineStatusMarkerProps['colors'];
}

export const OnlineStatusTag: React.FC<OnlineStatusTagProps> = ({ online, children, colors }) => {
  return (
    <Tag color="white" iconLeft={<OnlineStatusMarker colors={colors} online={online} size="small" />}>
      <OnlineStatusText online={online}>{children}</OnlineStatusText>
    </Tag>
  );
};
