import React from 'react';
import { FormField } from '@cp/ds/src/components/form';
import { SelectProps } from '@cp/ds/src/components/select/model';
import { useCareerOptions } from '../../lib';

export type CareerSelectProps = SelectProps;
export const CareerSelect: React.FC<CareerSelectProps> = (props) => {
  const options = useCareerOptions();
  return <FormField items={options} name="careerId" placeholder="Специальность" type="select" {...props} />;
};
