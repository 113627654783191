import { MetaPageView } from '@common/model/meta';
import React, { useCallback, useMemo, useState } from 'react';
import { Button } from '@cp/ds/src/components/button';
import { GridActionsCellItem, GridEventListener, GridRowParams } from '@mui/x-data-grid';
import { DeleteIcon, InfoSolidIcon } from '@cp/ds/src/icons';
import { ApiServerEntityIDType } from '@common/types';
import { DataGrid, GridColDef, GridToolbarContainer } from 'src/shared/ui/dataGrid';
import { adminRoutesApi } from '../api';
import { TempRoute, useAddTempRoute, useDeleteRoute, useSaveTempRoute, useTempRoutes } from '../model';
import { getPageIdOptions, pageTitles } from './cellPageId';

type ColumnValue = MetaPageView | TempRoute;

const useColumnsConfig = (onFaq?: (m: MetaPageView) => void): GridColDef<ColumnValue>[] => {
  const deleteRoute = useDeleteRoute();
  return useMemo(
    () => [
      {
        field: 'pageId',
        type: 'singleSelect',
        headerName: 'Тип',
        editable: true,
        valueOptions: getPageIdOptions(),
        valueFormatter: (params) => {
          return pageTitles[params.value];
        },
      },
      {
        field: 'path',
        minWidth: 200,
        editable: true,
      },
      {
        field: 'title',
        flex: 1,
        minWidth: 500,
        editable: true,
      },
      {
        field: 'description',
        flex: 1,
        minWidth: 500,
        editable: true,
      },
      {
        field: 'keywords',
        flex: 1,
        minWidth: 500,
        editable: true,
      },
      {
        field: 'breadcrumb',
        flex: 1,
        minWidth: 300,
        editable: true,
      },
      {
        field: 'h1',
        flex: 1,
        minWidth: 500,
        editable: true,
      },
      {
        field: 'props',
        editable: true,
        flex: 1,
        minWidth: 400,
        valueSetter: (p) => {
          let props = p.row.props;
          try {
            props = JSON.parse(p.value);
          } catch (e) {}
          return { ...p.row, props };
        },
        valueGetter: (p) => {
          return p.value ? JSON.stringify(p.value) : '';
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'FAQ',
        getActions: (params) => {
          return [
            <GridActionsCellItem icon={<DeleteIcon />} key="delete" label="delete" onClick={() => deleteRoute(params.row)} />,
            <GridActionsCellItem
              disabled={!onFaq || 'tempId' in params.row}
              icon={<InfoSolidIcon />}
              key="faq"
              label="faq"
              onClick={() => {
                if ('id' in params.row) {
                  onFaq?.(params.row);
                }
              }}
            />,
          ];
        },
      },
    ],
    [deleteRoute, onFaq],
  );
};

export interface RoutesTableProps {
  selectedId?: ApiServerEntityIDType;
  onSelected?: (pageId: ApiServerEntityIDType) => void;
  onFaqEdit?: (model: MetaPageView) => void;
}
export const RoutesTable: React.FC<RoutesTableProps> = ({ onSelected, onFaqEdit }) => {
  const [paginationModel, setPagination] = useState({ page: 1, pagination: 100 });
  const tempRoutes = useTempRoutes();
  const create = useSaveTempRoute();
  const add = useAddTempRoute();
  const { data, isLoading } = adminRoutesApi.endpoints.getPages.useQuery(paginationModel);
  const [update] = adminRoutesApi.endpoints.updatePage.useMutation();
  const gridColumns = useColumnsConfig(onFaqEdit);
  const processRowUpdate: (n: ColumnValue, o: ColumnValue) => Promise<ColumnValue> = useCallback(
    (n, o) => {
      if (!window.confirm('Сохранить изменения?')) {
        return Promise.resolve(o);
      }
      if ('id' in n) {
        return update(n).unwrap();
      }
      return create(n);
    },
    [update, create],
  );
  const rows = (tempRoutes as ColumnValue[]).concat(data?.items || []);

  const onRowClick: GridEventListener<'rowClick'> = useCallback(
    ({ row: model }: GridRowParams<ColumnValue>, event) => {
      if (event.defaultPrevented || !onSelected) {
        return;
      }
      if ('id' in model) {
        onSelected(model.id);
      }
    },
    [onSelected],
  );

  return (
    <DataGrid
      columns={gridColumns}
      density="compact"
      editMode="row"
      getEstimatedRowHeight={() => 56}
      getRowHeight={() => 'auto'}
      getRowId={(m) => ('id' in m ? m.id : m.tempId)}
      loading={isLoading}
      onPaginationModelChange={({ page, pageSize }) => {
        setPagination({ page: page + 1, pagination: pageSize });
      }}
      onRowClick={onRowClick}
      paginationMode="server"
      processRowUpdate={processRowUpdate}
      rowCount={data?.totalCount || 0}
      rows={rows}
      slots={{
        toolbar: () => (
          <GridToolbarContainer>
            <Button onClick={add} size="small" variant="secondary">
              новый роут
            </Button>
          </GridToolbarContainer>
        ),
      }}
      sx={{ '& .MuiDataGrid-cell': { fontSize: '14px', minHeight: '56px' } }}
    />
  );
};
