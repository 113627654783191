import React from 'react';
import { ClientPhotoPurpose } from '@common/model/client/photo/purpose';
import { AdminClientProfileViewModel } from '@common/model/client/profile/adminClientProfileViewModel';
import { useModal } from 'src/app/shared/hooks/useModal';
import { CLIENT_PHOTO_PICK_MODAL_NAME } from 'src/app/shared/components/modals/clientPhotoPick/const';

export const useClientPhotoEditingCreator = () => {
  const { openModal } = useModal();

  return [
    React.useCallback(
      ({ clientProfile, purpose }: { clientProfile: AdminClientProfileViewModel | undefined; purpose: ClientPhotoPurpose }) =>
        () => {
          if (!clientProfile) {
            return;
          }
          openModal(CLIENT_PHOTO_PICK_MODAL_NAME, { clientProfile, purpose });
        },
      [openModal],
    ),
  ] as const;
};
