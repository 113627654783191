import React from 'react';
import { CheckerProps } from '../model';
import { CheckmarkIcon } from '../../../icons';
import { CheckerRoot } from './root';

export const CheckboxChecker = ({ checked, size = 'normal', sx }: CheckerProps) => {
  return (
    <CheckerRoot size={size} sx={sx} variant="checkbox">
      <CheckmarkIcon sx={{ width: '100%', height: '100%', visibility: checked ? 'visible' : 'hidden' }} />
    </CheckerRoot>
  );
};
