import React, { ReactNode } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { Card } from '@cp/ds/src/components/card';
import { CardProps } from '@cp/ds/src/components/card/model';
import { Stack } from '@cp/ds/src/components/stack';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { styled } from '@cp/ds/src/theme/styled';
import { InfoIcon } from '@cp/ds/src/icons';
import { CatalogItemViewModel } from '@common/model/catalog/item/view';
import { Typography } from '@cp/ds/src/components/typography';
import { useIsMobile } from '@cp/ds/src/hooks';

const Root = styled(Card)({
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
});

const DESCRIPTION_BUTTON_SIZE = '1.5rem';

export interface ResumeCardProps extends CardProps {
  hidden?: ReactNode | ReactNode[];
  header?: ReactNode;
  achievements?: ReactNode | ReactNode[];
  profilePhoto?: ReactNode;
  mediaButton?: ReactNode;
  footer?: ReactNode;
  showDescription?: boolean;
  data?: CatalogItemViewModel;
}

export const ResumeCard: React.FC<ResumeCardProps> = ({
  mediaButton,
  hidden,
  header,
  achievements,
  profilePhoto,
  footer,
  showDescription,
  data,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [isDescriptionVisible, setDescriptionVisible] = React.useState(false);
  return (
    <Root {...props}>
      {header && (
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap', py: 1, px: 2 }}>
          {header}
        </Stack>
      )}
      {hidden}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          zIndex: 0,
        }}
      >
        {profilePhoto}
        <Stack
          sx={{
            alignItems: { xs: 'flex-start', md: 'flex-start' },
            justifyContent: { xs: 'flex-end', md: 'flex-start' },
            flexWrap: 'wrap',
            gap: 0.5,
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
            bottom: { xs: 0, md: 'auto' },
            ml: { xs: 1, md: 2 },
            mt: { xs: 0.75, md: 1.5 },
            mb: { xs: 1, md: 0 },
            mr: { xs: 0.75, md: 2 },
          }}
        >
          {achievements}
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            bottom: 20,
            transform: 'translateX(-50%)',
            zIndex: 2,
          }}
        >
          {mediaButton}
        </Box>
        {showDescription && Boolean(data?.translation?.description?.length) && (
          <>
            <Box
              onClick={(event) => {
                event.stopPropagation();
                if (data?.translation?.description) {
                  setDescriptionVisible(true);
                }
              }}
              role="button"
              sx={{
                position: 'absolute',
                top: { xs: 'auto', md: 0 },
                bottom: { xs: 0, md: 'auto' },
                right: 0,
                zIndex: 2,
                mt: { xs: 0.75, md: 1.5 },
                mb: { xs: 1, md: 0 },
                mr: { xs: 0.75, md: 2 },
                background: theme.palette.white,
                borderRadius: '50%',
                width: DESCRIPTION_BUTTON_SIZE,
                height: DESCRIPTION_BUTTON_SIZE,
              }}
              tabIndex={0}
            >
              <InfoIcon fontSize={DESCRIPTION_BUTTON_SIZE} />
            </Box>
            <Box
              onClick={(event) => {
                event.stopPropagation();
                setDescriptionVisible(false);
              }}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 3,
                visibility: isDescriptionVisible ? 'visible' : 'hidden',
                backgroundColor: theme.palette['gray dark'],
                px: 2,
                py: 1,
              }}
            >
              <Typography
                component="div"
                numberOfLines={isMobile ? 8 : 12}
                sx={{
                  color: theme.palette.white,
                }}
                variant="caption"
              >
                {data?.translation?.description}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {footer}
    </Root>
  );
};
