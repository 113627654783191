import { Sex } from '@common/model/client/profile/sex';

export const getLastSeenLabel = (sex?: Sex): string => {
  switch (sex) {
    case Sex.Female:
      return 'была';
    case Sex.Male:
      return 'был';
    default:
      return 'был(а)';
  }
};
