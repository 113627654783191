import React from 'react';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { Link as BaseLink, LinkProps } from '@cp/ds/src/components/link';
import { useVacancyUrl } from '../model';

export interface OpenVacancyLinkProps<T extends LinkProps = LinkProps> extends LinkProps {
  model: AdminCatalogItemViewModel;
  Link?: (p: T) => JSX.Element | null;
}
export const OpenVacancyLink: React.FC<OpenVacancyLinkProps> = ({ model, Link = BaseLink, ...props }) => {
  const url = useVacancyUrl(model);
  return <Link external href={String(url)} {...props} />;
};
