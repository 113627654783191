import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@cp/ds/src/components/box';
import { CatalogItemType } from '@common/model/catalog/item/type';
import { styled } from '@cp/ds/src/theme';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { AdminCatalogItemsListRequest, AdminCatalogItemsListResponse } from '@common/model/catalog/item/adminList.request';
import { ContentSkeleton } from '@cp/ds/src/components/contentSkeleton';
import { adminCatalogItemApi } from 'src/entities/catalog/item';
import { VacancyCard } from './card';

export interface VacancyListProps extends BoxProps {
  renderHeader?: (data: AdminCatalogItemsListResponse | undefined, parameters: { isLoading: boolean }) => ReactNode;
  request?: Omit<AdminCatalogItemsListRequest, 'type'>;
  skipRequest?: boolean;
  renderItem?: (model: AdminCatalogItemViewModel) => ReactNode;
}
const Root = styled(Box, { name: 'VacancyList' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  marginBlock: 0,
  paddingInline: 0,
}));

export const VacancyList: React.FC<VacancyListProps> = ({ request, skipRequest, renderItem, renderHeader, ...props }) => {
  const { data, isLoading } = adminCatalogItemApi.endpoints.getList.useQuery(
    { type: CatalogItemType.Vacancy, sorters: { createdAt: 'desc' }, ...request },
    { skip: skipRequest },
  );
  return (
    <Root component="ul" {...props}>
      {renderHeader?.(data, { isLoading })}
      {isLoading && <ContentSkeleton blocks={5} spacing={4} />}
      {data?.items.map((vacancy) => (renderItem ? renderItem(vacancy) : <VacancyCard component="li" key={vacancy.id} model={vacancy} />))}
    </Root>
  );
};
