import fetchFn from 'cross-fetch';
import qs from 'qs';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { HttpStatusCode } from '@cp/utils/const/httpStatusCode';
import { fetchBaseQuery } from '@cp/utils/rtk/query/fetchBaseQuery';
import { AppHeaders } from '@common/headers';
import { HttpHeader } from '@common/const/httpHeader';
import { Platform } from '@common/model/platform';
import { UserAgent } from '@common/const/userAgent';
import { API_SERVER_AUTH_TOKEN_TYPE } from '@cp/utils/apiServer/auth';
import { LocalizationSliceShape, localizationLanguageCodeSelector } from '../../localization';
import { config } from '../../config';
import { AuthenticationSliceShape, authenticationSlice, authenticationTokenSelector } from './authentication';
import { CoreApiError } from './model';
import { coreServiceTagTypes } from './const';

type StateShape = AuthenticationSliceShape & LocalizationSliceShape;

export interface ICoreApiOptions {
  platform?: Platform;
}

const options: ICoreApiOptions = {};

export const updateCoreApiOptions = (optionsUpdate: ICoreApiOptions = {}) => {
  Object.assign(options, optionsUpdate);
};

export const coreApi = createApi({
  reducerPath: 'coreApi',

  baseQuery: fetchBaseQuery<StateShape>({
    baseUrl: config.CORE_SERVER_ORIGIN,
    fetchFn,
    paramsSerializer: qs.stringify,
    credentials: 'include',
    prepareRequest: ({ body, params, headers, api }) => {
      const state = api.getState();
      const token = authenticationTokenSelector(state);
      const language = localizationLanguageCodeSelector(state);

      headers[AppHeaders.LanguageCode] = language;

      if (options.platform) {
        headers[HttpHeader.UserAgent] = {
          [Platform.Android]: UserAgent.MobileAppAndroid,
          [Platform.IOS]: UserAgent.MobileAppIOS,
        }[options.platform];
      }

      if (token) {
        headers[AppHeaders.Authorization] = `${API_SERVER_AUTH_TOKEN_TYPE} ${token}`;
      }

      return { body, params };
    },
    validateStatus: (response) => {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      return response.status >= 200 && response.status < 300;
    },
    handleError: ({ status, api: { dispatch } }) => {
      if (status === HttpStatusCode.Unauthorized) {
        dispatch(authenticationSlice.actions.setToken(null));
        return true;
      }

      return false;
    },
  }) as BaseQueryFn<unknown, unknown, CoreApiError>,

  tagTypes: coreServiceTagTypes,
  refetchOnReconnect: true,

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  endpoints: (builder) => ({
    // Empty
  }),
});
