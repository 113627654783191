import React, { PropsWithChildren, useMemo } from 'react';
import { IRouter, RouterContextProvider } from '@cp/shared/router';
import { useHistory } from 'react-router';

export const RouterProvider: React.FC<PropsWithChildren> = (props) => {
  const history = useHistory();
  const router: IRouter = useMemo(() => {
    return {
      get location() {
        return window.location;
      },
      navigate: (url, options) =>
        new Promise((resolve) => {
          const targetUrl = new URL(url, window.location.origin);
          if (window.location.origin === targetUrl.origin) {
            const method = options?.method === 'replace' ? history.replace : history.push;
            method({ pathname: targetUrl.pathname, search: targetUrl.search, hash: targetUrl.hash });
          } else if (targetUrl.protocol.startsWith('http')) {
            window.open(targetUrl, '_blank'); // open external urls in new tab
          } else {
            window.location.href = targetUrl.toString();
          }
          return resolve();
        }),
    };
  }, [history]);
  return <RouterContextProvider value={router}>{props.children}</RouterContextProvider>;
};
