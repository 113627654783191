import React from 'react';
import { RubSolidIcon } from '@cp/ds/src/icons/rubSolid';
import { coreSubscriptionsStats } from '../api';
import { formatNumber } from '../lib';
import { StatItem } from './statItem';

export const ActiveSubscriptions = () => {
  const { data, isLoading } = coreSubscriptionsStats.endpoints.getSubscriptionStats.useQuery();
  return (
    <StatItem
      Icon={RubSolidIcon}
      iconSxProps={{ color: 'green' }}
      isLoading={isLoading}
      label="Подписки"
      title={
        'Первая цифра - кол-во клиентов с действующей в данный момент оплаченной подпиской. Значение в скобках - количество клиентво с включенным Автопродлением в данный момент. \n' +
        '(в обоих показателях учитываются также и клиенты еоторые активировали промокод привязав карту)'
      }
      value={
        <>
          {formatNumber(data?.active.totalCount)} ({formatNumber(data?.renewable.totalCount)})
        </>
      }
    />
  );
};
