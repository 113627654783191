import React from 'react';
import { DotMark, DotMarkProps } from '../../../dotMark';
import { OnlineStatus, toOnlineStatus } from '../../model';
import { useTheme } from '../../../../hooks/useTheme';
import { mergeSx } from '../../../../utils/sx/merge';

export interface OnlineStatusMarkerProps extends Omit<DotMarkProps, 'color'> {
  online: boolean;
  colors?: Partial<Record<OnlineStatus, string>>;
}

export const OnlineStatusMarker: React.FC<OnlineStatusMarkerProps> = ({ online, colors, sx, ...props }) => {
  const theme = useTheme();
  const colorMap = { [OnlineStatus.Online]: theme.palette.green, [OnlineStatus.Offline]: 'transparent', ...colors };
  return (
    <DotMark
      color={colorMap[toOnlineStatus(online)]}
      sx={mergeSx(
        {
          border: `1px solid ${online ? theme.palette.white : 'transparent'}`,
        },
        sx,
      )}
      {...props}
    />
  );
};
