// @ts-expect-error esm import
import { cssToken } from '@cp/ui/utils';

export const name = 'CheckableAdvantage';

export const CssTokens = {
  colorBackground: cssToken(name, ['color', 'background']),
  colorBackgroundChecked: cssToken(name, ['color', 'background', 'checked']),
  colorText: cssToken(name, ['color', 'text']),
  colorTextChecked: cssToken(name, ['color', 'text', 'checked']),
};
