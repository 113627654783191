import { DialogMemberType } from '@common/model/dialog/dialogMemberType';
import { DialogMemberViewModel } from '@common/model/dialog/dialogMemberViewModel';

const SERVICE_DIALOG_MEMBER_NAME = 'Техподдержка';

export const getDialogMemberName = (member: DialogMemberViewModel): string => {
  return [DialogMemberType.Admin, DialogMemberType.Bot].includes(member.type)
    ? SERVICE_DIALOG_MEMBER_NAME
    : `${member.name}${member.surname ? ` ${member.surname}${member.surname.length === 1 ? '.' : ''}` : ''}`;
};
