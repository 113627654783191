import { useCallback } from 'react';
import { adminCatalogItemApi } from 'src/entities/catalog/item';

export const useVacancyDelete = (): [(id: number) => Promise<void>, { isLoading: boolean }] => {
  const [doDelete, params] = adminCatalogItemApi.endpoints.deleteCatalogItem.useMutation();
  const trigger = useCallback(
    (id: number) => (window.confirm('Удалить вакансию?') ? doDelete({ id }).unwrap() : Promise.reject(new Error('Operation cancelled'))),
    [doDelete],
  );
  return [trigger, params];
};
