import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { encodeQueryParams, generatePath as generatePathBase } from '../../lib';
import { CITY_PATH_PARAMETER } from './const';
import { PathGenerator } from './types';
import { routePathWithCity } from './city';

export const generatePath: PathGenerator = (
  { page, pageProps = undefined, pathParams: _pathParams, queryParams, pageRoutes },
  { logger } = {},
): string => {
  let routePath = '';
  const pathParams = { ..._pathParams };

  if (Array.isArray(pageRoutes)) {
    const targetRoute = pageRoutes.find(({ props }) => isEqual(omitBy(props ?? {}, isUndefined), omitBy(pageProps ?? {}, isUndefined)));

    if (targetRoute) {
      const { path, hasCitySeparatedContent, defaultCityAlias } = targetRoute;

      if (hasCitySeparatedContent) {
        routePath = routePathWithCity(path);
        pathParams[CITY_PATH_PARAMETER] = pathParams.city === defaultCityAlias ? undefined : pathParams.city;
      } else {
        routePath = path;
      }
    } else {
      logger?.warn(`Route not found for page ${page} and page props "${JSON.stringify(pageProps)}"`);
    }
  } else {
    logger?.warn(`Route not found for page ${page}`);
  }

  const queryString = queryParams ? encodeQueryParams(queryParams) : '';

  return `${generatePathBase(routePath, pathParams as Parameters<typeof generatePathBase>[1], { logger })}${queryString ? `?${queryString}` : ''}`;
};
