export enum NotificationSubscriptionType {
  Unknown = 'unknown',
  WebPush = 'webpush',

  /**
   * Firebase Cloud Messaging
   * @link https://firebase.google.com/docs/cloud-messaging
   */
  FCM = 'fcm',
}

export interface INotificationMessage {
  /**
   * Defines a title for the notification, which is shown at the top of the notification layout.
   */
  title: string;

  /**
   * A string representing the body text of the notification, which is displayed below the title.
   */
  body?: string;

  /**
   * A string containing the URL of the image used to represent the notification when there isn't enough space to display the notification itself; for example, the Android Notification Bar. On Android devices, the badge should accommodate devices up to 4x resolution, about 96x96px, and the image will be automatically masked.
   */
  badge?: string;

  /**
   * A string containing the URL of an icon to be displayed in the notification.
   */
  icon?: string;

  /**
   * A string containing the URL of an image to be displayed in the notification.
   */
  image?: string;

  /**
   * A string representing an identifying tag for the notification.
   */
  tag?: string;

  /**
   * Optional action on notification message click
   */
  onClick?: {
    type: 'navigate';
    url?: string;
  };
}

export enum INotificationDataType {
  UpdateUnreadCount = 'updateUnreadCount',
  // TODO: add types
}

export type INotificationData = {
  type: INotificationDataType.UpdateUnreadCount;
  unreadCount: number;
}; // TODO: describe more types as an objects union

export interface INotification {
  message?: INotificationMessage;
  data?: INotificationData;
}
