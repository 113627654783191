import { coreApi } from '@cp/shared/api/core';
import { generateApiUrl } from '@common/api/configure';
import { ApiRoute } from '@common/api/route';
import { StatsClientRequest } from '@common/model/stats/client';

export const coreClientStats = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getClientStats: builder.query<number, StatsClientRequest>({
      query: (params) => ({
        url: generateApiUrl(ApiRoute.StatsClient),
        params,
      }),
    }),
  }),
  overrideExisting: false,
});
