import { ClientProfileViewModel } from '@common/model/client';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds';
import { Box } from '@cp/ds/src/components/box';
import { Switch } from '@cp/ds/src/components/switch';
import { Typography } from '@cp/ds/src/components/typography';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { coreClientApi } from 'src/entities/client';

export const ToggleClientDocumentsCheck = ({ clientProfile }: { clientProfile?: ClientProfileViewModel }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [toggleClientDocumentsCheck, { isLoading: isMutationLoading }] = coreClientApi.endpoints.toggleClientDocumentsCheck.useMutation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: { xs: 'flex-start', [MOBILE_BREAKPOINT_THRESHOLD]: 'center' },
        backgroundColor: 'background.default',
        borderRadius: '12px',
        border: `1px solid ${theme.palette['gray light']}`,
        overflow: 'hidden',
        p: isMobile ? 1.5 : 2,
      }}
    >
      <Typography>{'Документы проверены'}</Typography>
      <Switch
        disabled={!clientProfile || isMutationLoading}
        onChange={() => {
          if (!clientProfile) {
            console.debug('Can`t togggle documents check. Client profile is empty');
            return;
          }
          toggleClientDocumentsCheck({ id: clientProfile.id });
        }}
        sx={{ flex: '0 0 0px' }}
        value={clientProfile?.isDocumentsChecked}
      />
    </Box>
  );
};
