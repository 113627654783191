import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TokenPayload } from '@common/model/auth';
const jwtDecode = <T extends object>(jwt: string | null): T | null => (jwt ? JSON.parse(atob(jwt.split('.')[1])) : null);

export interface AuthenticationState {
  token: string | null;
  tokenPayload: TokenPayload | null;
}

const initialState: AuthenticationState = {
  token: null,
  tokenPayload: null,
};

export const authenticationReducerPath = 'authentication';
export type AuthenticationSliceShape = { [authenticationReducerPath]: AuthenticationState };

export const authenticationSlice = createSlice({
  name: '@@app/authentication',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<AuthenticationState['token']>) {
      state.token = action.payload;
      state.tokenPayload = jwtDecode<TokenPayload>(action.payload);
    },
    clearToken() {
      return initialState;
    },
  },
});
