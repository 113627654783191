import { ClientType } from '@common/model/client/clientType';

export const typeToEmoji: Record<ClientType, string> = {
  [ClientType.Specialist]: '👩🏼',
  [ClientType.Employer]: '👔',
};
export const typeToTitle: Record<ClientType, string> = {
  [ClientType.Specialist]: 'специалист',
  [ClientType.Employer]: 'наниматель',
};
