import { Box } from '@cp/ds/src/components/box';
import { styled } from '@cp/ds/src/theme/styled';
import { Advantage, AdvantageTokens } from '../advantage';
import { CssTokens, name } from './const';

export const StyledInput = styled('input')({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  visibility: 'hidden',
});

export const StyledBox = styled(Box)({
  position: 'relative',
  display: 'inline-flex',
  cursor: 'pointer',
});

export const StyledAdvantage = styled(Advantage, { name, slot: 'Root' })<{ checked?: boolean }>(({ theme, checked }) => ({
  [AdvantageTokens.colorText]: CssTokens.colorText(theme.palette.text.primary),
  [AdvantageTokens.colorBackground]: CssTokens.colorBackground(theme.palette.background.default),
  [AdvantageTokens.colorBorder]: theme.palette['gray light'],
  flexGrow: 1,
  ...(checked && {
    [AdvantageTokens.colorText]: CssTokens.colorTextChecked(theme.palette.white),
    [AdvantageTokens.colorBackground]: CssTokens.colorBackgroundChecked(theme.palette['brand gray']),
    [AdvantageTokens.colorBorder]: CssTokens.colorBackgroundChecked(theme.palette['brand gray']),
  }),
}));
