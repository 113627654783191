import { DialogsFilterType } from '@common/model/dialog/filter';
import { useIsLoggedIn } from '../../currentUser';
import { coreDialogApi } from '../api';

interface UseUnreadMessagesCountProps {
  filter?: DialogsFilterType;
}

export const useUnreadMessagesCount = (props: UseUnreadMessagesCountProps = {}): number => {
  const isLoggedIn = useIsLoggedIn();
  const { data } = coreDialogApi.endpoints.getUnreadMessagesCount.useQuery({ filter: props.filter }, { skip: !isLoggedIn });
  return data?.count ?? 0;
};
