import qs from 'qs';
import { QueryParamsDictionary, QueryParamsNonEmptyDictionary, ValueDecoder } from './types';
import { decodeFloat } from './decodeFloat';
import { decodeBoolean } from './decodeBoolean';
import { decodeDate } from './decodeDate';
import { composeDecoders } from './composeDecoders';

export * from './types';
export * from './decodeFloat';
export * from './decodeBoolean';
export * from './decodeDate';
export * from './composeDecoders';

const decodeValue = composeDecoders(decodeFloat, decodeBoolean, decodeDate);

export const parseQueryParamValues = (dict: QueryParamsDictionary, opts?: { decoder?: ValueDecoder }) => {
  const { decoder } = { decoder: decodeValue, ...opts };
  return Object.entries(dict as QueryParamsNonEmptyDictionary).reduce((acc, [k, v]) => {
    if (typeof v === 'string') {
      acc[k] = decoder(v);
    } else if (Array.isArray(v)) {
      acc[k] = v.map((subValue) => {
        return decoder(String(subValue));
      });
    } else if (typeof v === 'object' && !(v instanceof Date)) {
      acc[k] = parseQueryParamValues(v);
    } else {
      acc[k] = v;
    }

    return acc;
  }, {} as QueryParamsNonEmptyDictionary);
};

export const decodeQueryString = <T extends QueryParamsDictionary>(
  queryString: string,
  defaultParams?: Partial<T>,
  parseOpts?: { decoder?: ValueDecoder },
): T => {
  const dict = qs.parse(queryString, {
    ignoreQueryPrefix: true,
    decoder: decodeValue,
    ...parseOpts,
  }) as QueryParamsNonEmptyDictionary;

  if (defaultParams) {
    for (const k in defaultParams) {
      dict[k] = dict[k] ?? defaultParams[k];
    }
  }

  return dict as unknown as T;
};

export const encodeQueryParams = <T extends QueryParamsDictionary>(queryParams: T): string => {
  return qs.stringify(queryParams, { serializeDate: (date) => date.toISOString(), encode: false });
};
