import React from 'react';
import { useVacancyDates } from '@cp/entities/vacancy';
import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { CareerTag } from '@cp/entities/career';
import { Box } from '@cp/ds/src/components/box';
import { Typography } from '@cp/ds/src/components/typography';
import { VacancyCard as BaseCard, VacancyCardProps as BaseCardProps } from 'src/entities/vacancy';

export interface VacancyCardProps extends Partial<BaseCardProps> {
  model: AdminCatalogItemViewModel;
}

export const VacancyCard: React.FC<VacancyCardProps> = ({ model, children, title, ...props }) => {
  const dates = useVacancyDates(model);
  return (
    <BaseCard
      matched={'matchedAt' in model}
      tags={<CareerTag careerId={model.careerId} />}
      title={model.translation?.title || dates || title}
      {...props}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
        {model.translation?.description && <Typography>{model.translation.description}</Typography>}
        {children}
      </Box>
    </BaseCard>
  );
};
