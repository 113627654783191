import React from 'react';
import { useTheme } from '@cp/ds/src/hooks';
import { Typography } from '@cp/ds/src/components/typography';
import { DialogNotificationCounterProps } from './types';

export const DialogNotificationCounter: React.FC<DialogNotificationCounterProps> = ({ value, size = 'normal' }) => {
  const theme = useTheme();

  if (!value) {
    return null;
  }

  const containerSize = size === 'normal' ? '1.25rem' : '0.875rem';
  const borderRadius = size === 'normal' ? '0.6125rem' : '0.4375rem';

  return (
    <Typography
      sx={{
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: containerSize,
        minWidth: containerSize,
        px: 0.25,
        borderRadius,
        backgroundColor: theme.palette['brand pink'],
        color: theme.palette.white,
      }}
      variant={size === 'normal' ? 'small text bold' : 'extraSmall'}
    >
      {value}
    </Typography>
  );
};
