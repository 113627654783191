import React, { ReactNode } from 'react';
import { Box } from '@cp/ds/src/components/box';
import { SxProps, styled } from '@cp/ds';
import { EntityId } from '@reduxjs/toolkit';
import { DialogMessageBlockPlain } from '../../model/dialogMessageDateBlock';
import { DialogMessagesDateBlock } from '../dialogMessagesDateBlock';
import { useDialogTheme } from '../theme';

const name = 'DialogMessageList';
const Root = styled(Box, { name })({ display: 'flex', flexFlow: 'column' });

export interface DialogMessageListProps {
  dialogId: EntityId;
  blocks: DialogMessageBlockPlain[];
  sx?: SxProps;
  children?: ReactNode | ReactNode[];
}

export const DialogMessageList: React.FC<DialogMessageListProps> = (props) => {
  const dialogTheme = useDialogTheme();
  return (
    <Root sx={{ gap: dialogTheme.listGap, ...props.sx }}>
      {props.blocks.map((block, index) => (
        <DialogMessagesDateBlock block={block} dialogId={props.dialogId} isLast={index === props.blocks.length - 1} key={block.title} />
      ))}
      {props.children}
    </Root>
  );
};
DialogMessageList.displayName = name;
