import { AdminCatalogItemViewModel } from '@common/model/catalog/item/admin.view';
import { Tag, TagProps } from '@cp/ds/src/components/tag';
import React from 'react';

interface AutoSelectionTagProps extends TagProps {
  data: AdminCatalogItemViewModel;
}

export const AutoSelectionTag: React.FC<AutoSelectionTagProps> = ({ data, ...props }) => {
  if (!data.autoSelection) {
    return null;
  }

  return (
    <Tag color="peach" size="small" {...props}>
      Не хочу выбирать
    </Tag>
  );
};
