import { CoreClientApiTags, coreApi } from '@cp/shared/api/core';
import { CatalogItemViewModel } from '@common/model/catalog/item/view';
import { CatalogItemAuthorContactsReponse } from '@common/model/catalog/item/author/contacts';
import { ApiServerEntityIDType } from '@cp/utils/apiServer/types';
import { CatalogItemFeedbackListRequest, CatalogItemFeedbackListResponse } from '@common/model/catalog/item/feedback.list.request';
import { ApiRoutePathParams } from '@common/api/routePathParams';
import { ApiRoute } from '@common/api/route';
import { CatalogItemConnectResponse } from '@common/model/catalog/item/connect.response';
import { generateApiUrl } from '@common/api/configure';
import { apiRouteMethodDict } from '@common/api/routeMethod';
import { CatalogItemProposalsRequest, CatalogItemProposalsResponse } from '@common/model/catalog/item/proposal';
import { isFeedbackRatingPositive } from '@common/model/catalog/feedback';
import { CoreCatalogItemApiTags, coreCatalogItemApiTagTypes } from './tags';

export const coreCatalogItemApi = coreApi
  .enhanceEndpoints({
    addTagTypes: coreCatalogItemApiTagTypes,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getById: builder.query<CatalogItemViewModel, { id: number }>({
        query: ({ id }) => {
          return {
            url: `/catalog/items/${id}`,
          };
        },
        providesTags: (_result, _error, { id }) => [{ type: CoreCatalogItemApiTags.CatalogItem, id }],
      }),

      getCatalogItemAuthorContacts: builder.query<CatalogItemAuthorContactsReponse, { id: ApiServerEntityIDType }>({
        query: ({ id }) => ({
          url: `/catalog/items/${id}/author/contacts`,
        }),
        providesTags: (_result, _error, { id }) => [{ type: CoreCatalogItemApiTags.CatalogItemAuthorContacts, id }],
      }),

      getFeedbacks: builder.query<
        CatalogItemFeedbackListResponse & { stats: { positiveCount: number; negativeCount: number } },
        CatalogItemFeedbackListRequest & { id: number }
      >({
        query: ({ id, ...params }) => ({
          url: `/catalog/items/${id}/feedbacks`,
          params,
        }),
        providesTags: (_result, _error, { id }) => [{ type: CoreCatalogItemApiTags.ResumeFeedbacks, id }],
        // TODO: remove when backend counting will be implemented
        // (created as a part of task https://ru.yougile.com/team/2c7dacf7a810/#YOU-2592)
        transformResponse: (baseResult: CatalogItemFeedbackListResponse) => {
          return {
            ...baseResult,
            stats: baseResult.items.reduce(
              (acc, { rating }) => {
                if (isFeedbackRatingPositive(rating)) {
                  acc.positiveCount++;
                } else {
                  acc.negativeCount++;
                }
                return acc;
              },
              {
                positiveCount: 0,
                negativeCount: 0,
              },
            ),
          };
        },
      }),

      connectWithAuthor: builder.mutation<CatalogItemConnectResponse, ApiRoutePathParams[ApiRoute.CatalogItemAuthorConnect]>({
        query: (params) => ({
          url: generateApiUrl(ApiRoute.CatalogItemAuthorConnect, params),
          method: apiRouteMethodDict[ApiRoute.CatalogItemAuthorConnect],
        }),
      }),

      response: builder.mutation<CatalogItemConnectResponse, ApiRoutePathParams[ApiRoute.CatalogItemResponse]>({
        query: (params) => ({
          url: generateApiUrl(ApiRoute.CatalogItemResponse, params),
          method: apiRouteMethodDict[ApiRoute.CatalogItemResponse],
        }),
        invalidatesTags: [CoreClientApiTags.ClientProfile],
      }),

      getProposals: builder.query<CatalogItemProposalsResponse, CatalogItemProposalsRequest>({
        query: ({ catalogItemId, ...params }) => ({
          url: generateApiUrl(ApiRoute.CatalogItemGetProposals, { catalogItemId }),
          method: apiRouteMethodDict[ApiRoute.CatalogItemGetProposals],
          params,
        }),
      }),
    }),
  });
