import { ComponentType, MouseEvent, MouseEventHandler, PropsWithChildren, createElement, useCallback } from 'react';
import { useRouter } from './useRouter';
import { IRouter } from './router.interface';

interface ComponentProps extends PropsWithChildren {
  href?: string | URL;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
}

export const withRouter =
  <P extends { router: IRouter }>(Component: ComponentType<P>) =>
  (props: Omit<P, 'router'>) => {
    const router = useRouter();
    return createElement(Component, { ...props, router } as P);
  };

/**
 * A higher-order function that wraps a component and provides router functionality.
 */
export const withRouteHandler =
  <T extends ComponentProps = ComponentProps>(Component: ComponentType<T>) =>
  ({ onClick: clickHandler, ...props }: T) => {
    const router = useRouter();
    const onClick: T['onClick'] = useCallback(
      (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        clickHandler?.(e);
        if (e.defaultPrevented || !props.href || !router || e.currentTarget.getAttribute('target') === '_blank') {
          return;
        }
        e.preventDefault();
        return router.navigate(props.href);
      },
      [clickHandler, props.href, router],
    );
    return createElement(Component, { onClick, ...props } as T);
  };
