import { AdminClientProfileViewModel } from '@common/model/client/profile/adminClientProfileViewModel';
import { NotificationSubscriptionType } from '@common/model/notification';
import { Box, BoxProps } from '@cp/ds/src/components/box';
import React from 'react';

export const platformIcons: Record<NotificationSubscriptionType, [string, string]> = {
  [NotificationSubscriptionType.WebPush]: ['🕸️', 'Web'],
  [NotificationSubscriptionType.Unknown]: ['❔', 'Неизвестно'],
  [NotificationSubscriptionType.FCM]: ['🍏', 'Firebase'],
};

export interface NotificationSubscriptionsProps extends BoxProps {
  data: AdminClientProfileViewModel['notificationPlatforms'];
}

export const NotificationSubscriptions: React.FC<NotificationSubscriptionsProps> = ({ data, ...restProps }) => {
  return <Box {...restProps}>{data?.map((platform) => platformIcons[platform][0])}</Box>;
};
