import React, { FC, PropsWithChildren, useMemo } from 'react';
import { ClientPageResolver, ClientWebPageResolverProvider, coreRouterApi, createRoutesDict } from '@cp/entities/clientWebRoutes';
import { ClientWebRouteViewModel } from '@common/model/meta/clientWebRouteViewModel';
import { useConfig } from '@cp/shared/config';

export const ClientWebRouteResolver: FC<PropsWithChildren> = (props) => {
  const config = useConfig();
  const { data: routesResponse } = coreRouterApi.endpoints.getClientWebRoutes.useQuery();
  const routesRef = React.useRef<ClientWebRouteViewModel[]>([]);

  const routesDict = React.useMemo(() => {
    if (routesResponse) {
      routesRef.current = routesResponse;
    }

    return createRoutesDict(routesRef.current);
  }, [routesResponse]);

  const resolver = useMemo(() => {
    return new ClientPageResolver(routesDict, config.getOrThrow('CLIENT_WEB_ORIGIN'));
  }, [routesDict, config]);

  return <ClientWebPageResolverProvider value={resolver}>{props.children}</ClientWebPageResolverProvider>;
};
