import { ClientWebPage } from '@common/model/meta/clientWebPage';

export const pageTitles: Record<ClientWebPage, string> = {
  [ClientWebPage.Home]: 'Домашняя',
  [ClientWebPage.Catalog]: 'Каталог',
  [ClientWebPage.CatalogItem]: 'Элемент каталога',
  [ClientWebPage.AboutUs]: 'О нас',
  [ClientWebPage.PaymentAndRefund]: 'Оплата',
  [ClientWebPage.FAQ]: 'FAQ',
  [ClientWebPage.Feedbacks]: 'Отзывы',
  [ClientWebPage.Contacts]: 'Контакты',
  [ClientWebPage.LegalInformation]: 'LegalInformation',
  [ClientWebPage.PrivacyPolicy]: 'PrivacyPolicy',
  [ClientWebPage.NotFound]: 'NotFound',
  [ClientWebPage.WriteToDirector]: 'WriteToDirector',
  [ClientWebPage.UserAgreement]: 'UserAgreement',
  [ClientWebPage.Support]: 'Support',
  [ClientWebPage.SignupProvider]: 'SignupProvider',
  [ClientWebPage.Profile]: 'Profile',
  [ClientWebPage.ProfileVacancies]: 'ProfileVacancies',
  [ClientWebPage.ProfileFavorites]: 'ProfileFavorites',
  [ClientWebPage.ProfilePhoto]: 'ProfilePhoto',
  [ClientWebPage.LegacyPaymentFinish]: 'LegacyPaymentFinish',
  [ClientWebPage.ProfileMessages]: 'ProfileMessages',
  [ClientWebPage.Confirmation]: 'Confirmation',
  [ClientWebPage.OAuth]: 'OAuth',
  [ClientWebPage.Team]: 'Team',
  [ClientWebPage.ProfileSchedule]: 'ProfileSchedule',
  [ClientWebPage.ProfileRegularPartners]: 'ProfileRegularPartners',
  [ClientWebPage.ProfileBilling]: 'ProfileBilling',
  [ClientWebPage.Insurance]: 'Insurance',
  [ClientWebPage.Intro]: 'Intro',
};

export type Option = { value: number; label: string };

export const getPageIdOptions = (): Option[] => {
  return Object.entries(pageTitles).map(([value, label]) => ({ value, label }));
};
