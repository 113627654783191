import React, { HTMLAttributes } from 'react';
import { ClientType } from '@common/model/client/clientType';
import { SxProps, styled } from '@cp/ds/src/theme';
import { typeToEmoji, typeToTitle } from '../model';

const Root = styled('span')();

export interface ClientTypeIconProps extends HTMLAttributes<HTMLSpanElement> {
  type?: ClientType;
  sx?: SxProps;
}

export const ClientTypeIcon = ({ type, ...props }: ClientTypeIconProps) => {
  return (
    <Root title={type ? typeToTitle[type] : undefined} {...props}>
      {type ? typeToEmoji[type] : '👤'}
    </Root>
  );
};
