import { ValueDecoder } from './types';

export const decodeBoolean: ValueDecoder = (value) => {
  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined,
  };
  if (value in keywords) {
    return keywords[value];
  }
  return value;
};
