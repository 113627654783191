import { clientWebPageConfigDict } from '@common/model/meta/clientWebPageConfig';
import { ROUTES_DEFAULT_CITY_ALIAS } from '@common/const/routes/defaultCity';
import { ClientWebPage } from '@common/model/meta/clientWebPage';
import { ClientWebRouteViewModel } from '@common/model/meta/clientWebRouteViewModel';
import { RoutesDictionary, RoutesDictionaryEntry } from './types';

export const createRoutesDict = (routes: ClientWebRouteViewModel[] | undefined) => {
  const routesDict =
    routes?.reduce((acc, { id, path, props, pageId: _pageId }) => {
      if (!path) {
        return acc;
      }

      const pageId = _pageId as ClientWebPage;
      const { hasCitySeparatedContent = false } = clientWebPageConfigDict[pageId] ?? {};

      if (!acc[pageId]) {
        acc[pageId] = [];
      }

      (acc[pageId] as RoutesDictionaryEntry[]).push({
        routeId: id,
        path,
        props: props ?? {},
        hasCitySeparatedContent,
        defaultCityAlias: ROUTES_DEFAULT_CITY_ALIAS,
      });

      return acc;
    }, {} as RoutesDictionary) ?? {};

  return routesDict;
};
