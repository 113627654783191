import React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { DialogMessageViewModel } from '@common/model/dialog/message/dialogMessageViewModel';
import { MessageType } from '@common/model/dialog/message/messageType';

export interface MessageComponentProps<T extends MessageType = MessageType> {
  message: DialogMessageViewModel<T>;
  /**
   * In preview mode message component should render primitive and short content.
   * NOTE: For now (24.10.24) it shoud be text only content
   */
  isPreview?: boolean;
  dialogId: EntityId;
}
export interface MessageListBottomProps {
  lastMessageId?: EntityId;
  dialogId: EntityId;
}

export type DialogContextType = {
  useMessageResolver: (props: { id: EntityId }) => DialogMessageViewModel | undefined;
  MessageComponent: React.FC<MessageComponentProps>;
  MessageListBottom?: React.FC<MessageListBottomProps>;
};
const DialogContext = React.createContext<DialogContextType>({} as DialogContextType);
export const DialogContextProvider = DialogContext.Provider;
export const useDialogContext = () => React.useContext(DialogContext);
