const f = Intl.NumberFormat('ru-ru');
export const formatNumber = (value?: number | string) => {
  switch (typeof value) {
    case 'undefined':
      return '---';
    case 'number':
      return f.format(value);
    case 'string':
      if (!Number.isNaN(Number.parseInt(value))) {
        return f.format(Number.parseInt(value));
      } else if (!Number.isNaN(Number.parseFloat(value))) {
        return f.format(Number.parseFloat(value));
      }
      return '---';
    default:
      return value;
  }
};
