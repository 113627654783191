import { Trademark } from '@cp/ds/src/components/trademark';
import React from 'react';
import { useTheme } from '@cp/ds/src/hooks/useTheme';
import { LogoIcon } from '@cp/ds/src/icons/logo';
import { Box } from '@cp/ds/src/components/box';
import { styled } from '@cp/ds/src/theme/styled';
// @ts-expect-error esm import
import { cssToken } from '@cp/ui/utils';
import { CommonStats } from 'src/widgets/commonStats';
import { HEADER_HEIGHT, LAYOUT_SIDE_INDENT_DESKTOP, LAYOUT_SIDE_INDENT_MOBILE } from '../../const';

const name = 'PageHeader';
const sideIndent = cssToken(name, 'sideIndent');

const Root = styled(Box, { name })(({ theme }) => ({
  [sideIndent]: theme.spacing(LAYOUT_SIDE_INDENT_MOBILE),
  position: 'relative',
  zIndex: 4,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.white,
  height: `${HEADER_HEIGHT}px`,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
  paddingLeft: sideIndent(),
  paddingRight: sideIndent(),
  [theme.breakpoints.up('md')]: {
    [sideIndent]: theme.spacing(LAYOUT_SIDE_INDENT_DESKTOP),
  },
}));

const LogoContainer = styled(Box, { name, slot: 'LogoContainer' })(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  width: `calc(270px - ${sideIndent()})`,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const PageHeader: React.FC = () => {
  const theme = useTheme();

  return (
    <Root>
      <LogoContainer>
        <Trademark size={1.7} sx={{ display: { xs: 'none', lg: 'inherit' } }} />
        <LogoIcon fontSize="large" sx={{ color: theme.palette['brand pink'], display: { xs: 'none', md: 'inherit', lg: 'none' } }} />
      </LogoContainer>
      <Box>
        <CommonStats />
      </Box>
    </Root>
  );
};
